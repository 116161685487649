import useUserDataStore from "./useUserDataStore";

export interface usePermissionsReturns {
  canAccessNeossi: ({ appSlug }: { appSlug: any }) => any;
  canValidate: any;
  canRegister: any;
  canViewVatManagement: any;
  canViewManagment: any;
  canViewAdministrator: any;
  canRollback: any;
  canDeleteBulk: any;
  canView: boolean;
  canDisplayRemarksInput: any;
  canImportData: any;
  canAccessNeoCoffre: any;
  isATC: boolean;
  isOPS: boolean;
  canDisplayValidatedBookEntry: boolean;
  canDisplayBulksToAssign: boolean;
}
/**
 * Custom hook to determine user permissions based on their stored data.
 *
 * This hook retrieves the user's permissions from the `useUserDataStore` and
 * evaluates various permission flags related to different application features.
 * It checks for specific permissions to determine access rights for actions
 * such as registering, validating, viewing management interfaces, and more.
 *
 * @returns {usePermissionsReturns} An object containing boolean flags and functions
 * to check access permissions for various application features.
 */
export default function usePermissions(): usePermissionsReturns {
  const userData = useUserDataStore(state => state.userData);
  const permissions = userData?.permissions;

  const ATCPermissions = [
    "neossi-api.can-register-book-entries",
    "neossi-api.can-create-book-entries",
    "neossi-api.can-update-book-entries",
    "neossi-api.can-delete-entry-items",
    "neossi-api.can-update-entry-items",
    "neossi-api.can-create-entry-items",
    "neossi-api.can-delete-entry-vats",
    "neossi-api.can-view-book-entries",
    "neossi-api.can-create-entry-vats",
    "neossi-api.can-update-entry-vats",
    "neossi-api.can-view-entry-items",
    "neossi-api.can-view-entry-vats",
    "central-api.can-view-counterparts",
    "central-api.can-view-subsidiaries",
    "central-api.can-view-accountings",
    "central-api.can-view-providers",
    "central-api.can-view-clients",
    "central-api.can-view-fiscals",
    "central-api.can-view-books",
    "central-api.can-view-vats",
    "central-api.can-import",
    "banks-api.can-view-companies-banks",
    "neodocs-api.can-view-bulks",
    "neossi-api.can-view-most-used-counterparts",
  ];

  const ValidatorPermissions = [
    "neossi-api.can-validate-book-entries",
    "neossi-api.can-create-book-entries",
    "neossi-api.can-update-book-entries",
    "neossi-api.can-delete-entry-items",
    "neossi-api.can-create-entry-items",
    "neossi-api.can-update-entry-items",
    "neossi-api.can-view-book-entries",
    "neossi-api.can-delete-entry-vats",
    "neossi-api.can-create-entry-vats",
    "neossi-api.can-update-entry-vats",
    "neossi.can-validate",
    "central-api.can-view-accountings",
    "neossi-api.can-view-entry-items",
    "neossi-api.can-view-entry-vats",
    "central-api.can-view-counterparts",
    "central-api.can-view-subsidiaries",
    "central-api.can-view-providers",
    "central-api.can-view-clients",
    "central-api.can-view-fiscals",
    "central-api.can-view-books",
    "central-api.can-view-vats",
    "central-api.can-import",
    "banks-api.can-view-companies-banks",
    "neodocs-api.can-view-bulks",
    "neossi-api.can-view-most-used-counterparts",
  ];

  // Applications Permissions
  const canAccessNeossi = ({ appSlug }) =>
    permissions && !!permissions.find(item => item === `${appSlug}.can-access`);

  const ATCcanViewForm = ATCPermissions.every(permission => permissions.includes(permission));
  const ValidatorCanViewForm = ValidatorPermissions.every(permission =>
    permissions.includes(permission),
  );

  const canValidate =
    permissions &&
    permissions.find(
      item => item === "neossi-api.can-validate-book-entries" || item === "neossi.can-validate",
    );
  const canRegister =
    permissions && permissions.find(item => item === "neossi-api.can-register-book-entries");

  const canViewVatManagement =
    permissions && permissions.find(item => item === "neossi.can-display-vat-management");

  const canViewManagment =
    permissions && permissions.find(item => item === "neossi-api.can-view-management");

  const canViewAdministrator =
    permissions && permissions.find(item => item === "neossi-api.can-view-administration");

  const canRollback =
    permissions && permissions.find(item => item === "neodocs-api.can-rollback-bulks");

  const canDeleteBulk =
    permissions && permissions.find(item => item === "neossi.can-display-button-remove-bulk");

  const canDisplayRemarksInput =
    permissions && permissions.find(item => item === "neossi.can-display-remarks-input");

  const canImportData = permissions && permissions.find(item => item === "central-api.can-import");

  const canAccessNeoCoffre = permissions?.find(item => item === "neocoffre.can-access");

  const isATC = canRegister && !canValidate;
  const isOPS = canValidate;

  const canView = isATC ? ATCcanViewForm : ValidatorCanViewForm;

  const canDisplayValidatedBookEntry = Boolean(
    permissions?.find(item => item === "neossi.can-display-validated-book-entry") ||
      userData.isNewraiUser,
  );

  const canDisplayBulksToAssign = permissions?.find(
    item => item === "neoscan-expert.can-display-bulks-to-assign",
  );

  return {
    canAccessNeossi,
    canValidate,
    canRegister,
    canViewVatManagement,
    canViewManagment,
    canViewAdministrator,
    canRollback,
    canDeleteBulk,
    canDisplayRemarksInput,
    canImportData,
    canAccessNeoCoffre,
    canView,
    isATC,
    isOPS,
    canDisplayValidatedBookEntry,
    canDisplayBulksToAssign,
  };
}
