import { NoAccess } from "@newrai/ant-design";
import PrivateRoute from "config/routes/PrivateRoute";
import { urlDashboard } from "config/settings";
import { Suspense, lazy } from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import LoadingPage from "views/Base/LoadingPage";
import Page404 from "views/Base/Page404";

const BookEntry = lazy(() => import("views/Bulk"));
const Home = lazy(() => import("views/Home"));
const EditDocumentVat = lazy(() => import("views/EditAccountingVat/index"));
const DocumentsTable = lazy(() => import("views/AccountingVat/index"));
const PreviewDocument = lazy(() => import("views/PreviewDocument/index"));

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Home />} />
        <Route path="accounting-firm/:accountingFirmId" element={<Home />} />
        <Route path="accounting-firm/:accountingFirmId/company/:companyId" element={<Home />} />

        <Route path="bulk/:bulkid" element={<BookEntry />} />
        <Route path="bulk/:bulkid/book-entry/:bookEntryId" element={<BookEntry />} />
        <Route path="bulk/:bulkid/document/:documentId" element={<BookEntry />} />

        <Route path="preview" element={<PreviewDocument />} />
        <Route path="accounting-vat">
          <Route index element={<DocumentsTable />} />
          <Route path="edit" element={<EditDocumentVat />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Page404 />
          </Suspense>
        }
      />
      <Route path="no-access" element={<NoAccess dashboardUrl={urlDashboard} />} />
    </>,
  ),
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export { routes };
