import { ErrorBoundary } from "@newrai/ant-design";
import ConfigProvider from "antd/es/config-provider";
import localeEN from "antd/lib/locale/en_US";
import localeFr from "antd/lib/locale/fr_FR";
import i18n from "config/i18n/i18n";
import { routes } from "config/routes";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import Cookies from "js-cookie";
import { useRef } from "react";
import { I18nextProvider } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import "./resources/styles/styles.less";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(localeData);

function App(): JSX.Element {
  const cookie = useRef();

  const checkCookie = () => {
    if (!cookie.current) {
      cookie.current = Cookies.get("access_token");
    } else if (cookie.current !== Cookies.get("access_token")) {
      window.location.reload();
    }
  };

  window.setInterval(checkCookie, 1000);

  return (
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        <ConfigProvider locale={i18n.language === "en" ? localeEN : localeFr}>
          <RouterProvider
            router={routes}
            future={{
              v7_startTransition: true,
            }}
          />
        </ConfigProvider>
      </ErrorBoundary>
    </I18nextProvider>
  );
}

export default App;
