import { SidebarApps } from "@newrai/ant-design";
import useUserDataStore from "hooks/useUserDataStore";
import { useParams } from "react-router-dom";
import { Params } from "types/common";
import useBulkStore from "views/Bulk/hooks/useBulkStore";

export default function AppsMenu(): React.ReactElement {
  const userData = useUserDataStore(state => state.userData);

  const companySelected = useBulkStore(state => state.company);
  const { companyId, accountingFirmId }: Params = useParams();
  const companyID = companySelected?.id || companyId;
  const accountingFirmID = accountingFirmId || companySelected?.accountingFirm.id;

  return (
    <SidebarApps
      userData={userData}
      urlParams={{
        companyId: companyID,
        accountingFirmId: accountingFirmID,
      }}
    />
  );
}
